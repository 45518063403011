<template>
  <div class="question-item-edit">
    <div class="question-item-edit__name">
      <div class="question-item-edit__name-label mb-1">
        <span></span>
      </div>
      <div class="question-item-edit__name-text">
        <v-textarea
          v-model="value.Text"
          :autofocus="autofocus"
          maxlength="1000"
          rows="1"
          auto-grow
          hide-details
          :label="label"
          v-up-first-symbol
          required
          :rules="[(value) => !!value]"
        ></v-textarea>
      </div>
    </div>
    <div v-if="showExpend" class="question-item-edit__expend">
      <v-checkbox hide-details v-model="value.IsCorrect"></v-checkbox>
    </div>
    <div class="question-item-edit__actions">
      <v-btn
        v-if="isDeletable"
        @click.stop.prevent="del()"
        icon
        color="error"
        title="Удалить"
      >
        <m-icon icon="tool-delete" s16></m-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    showExpend: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Вопрос",
    },
    isDeletable: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isEdit: true,
    };
  },
  methods: {
    del() {
      this.$emit("delete", this.value);
    },
  },
};
</script>
<style lang="scss">
.question-item-edit {
  border-bottom: solid 1px #c6c8d5;
  display: flex;
  &__name {
    flex-grow: 1;
    &-text {
      color: rgb(33, 33, 33);
      font-weight: 500;
      font-size: 14px;
      line-height: 158%;
      letter-spacing: -0.01em;

      .v-input {
        margin-top: 0;
        padding-top: 0;
      }
    }
    &-label {
      font-weight: 500;
      font-size: 12px;
      line-height: 158%;
      color: rgb(33, 33, 33);
    }
  }
  &__actions {
  }
  &__expend {
    width: 150px;
    .v-input {
      margin-top: 0;
    }
    .v-input--selection-controls__input {
      margin: 0 auto;
    }
  }
}
</style>
